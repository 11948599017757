import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CustomImage from './custom-image'
import Grid from './grid'
import LoadMore from './load-more-btn'
import PlayBtn from './play-btn'
import remocoClient from '../remocoClient'
import {
    get,
    getHeroImageSize,
    getIdentifierFromPath,
    getRelevantEntriesData,
    sendGaPlayClickEvent,
    setGaScreenName,
} from '../utils'

const NUM_CATEGORY_ITEMS_TO_ADD = 10

function addCategoryItemsGrid(
    id,
    categoryItems,
    setCategoryItems,
    setCategoryInfo,
    offset,
    setOffset,
    showLoadBtn,
    setShowLoadBtn
) {
    if (categoryItems && categoryItems.length > 0) {
        let gridFix = ''
        if (categoryItems.length < 5) {
            // add class to fix card width for a small number of cards
            gridFix = 'grid-adjust'
        }
        return (
            <>
                <Grid data={categoryItems} gridFix={gridFix} />
                {showLoadBtn ? (
                    <LoadMore
                        onClickHandler={async () =>
                            await loadMoreCategoryItems(
                                id,
                                categoryItems,
                                offset,
                                setOffset,
                                setCategoryItems,
                                setCategoryInfo,
                                setShowLoadBtn
                            )
                        }
                    />
                ) : (
                    ''
                )}
            </>
        )
    }
    return null
}

async function loadMoreCategoryItems(
    id,
    categoryItems,
    offset,
    setOffset,
    setCategoryItems,
    setCategoryInfo,
    setShowLoadBtn
) {
    const newOffset = offset + NUM_CATEGORY_ITEMS_TO_ADD

    await getRelevantCategoryPageData(id, newOffset, categoryItems, setCategoryItems, setCategoryInfo, setShowLoadBtn)

    setOffset(newOffset)
}

async function getCategoryInfo(id) {
    const categoryInfo = await remocoClient.getCategory(id, 1)

    const code = get(['data', 'code'], categoryInfo) || id
    const title = get(['data', 'title'], categoryInfo) || ''
    const image = get(['data', 'resources', 'poster_16-9', 'value'], categoryInfo) || ''

    return { code, title, image }
}

function filterOutCategoryItemsWithNoMedia(categoryItems) {
    return categoryItems.filter(ep => {
        return !!ep.source
    })
}

function filterOutDuplicates(categoryItems = [], newCategoryItems = []) {
    return newCategoryItems.filter(ep => {
        const hasFoundDuplicate = !!categoryItems.find(e => {
            return e.id === ep.id
        })

        return !hasFoundDuplicate
    })
}

async function getCategoryData(id, offset) {
    const expand = 3
    const limit = NUM_CATEGORY_ITEMS_TO_ADD
    const categoryData = await remocoClient.getCategoryEvents(id, expand, limit, offset)

    return categoryData
}

function getCategoryItemsInfo(categoryData, categoryItems) {
    const nextCategoryItemsData = categoryData.list || []
    const relevantCategoryItemsData = filterOutCategoryItemsWithNoMedia(getRelevantEntriesData(nextCategoryItemsData))
    const newCategoryItemsData = filterOutDuplicates(categoryItems, relevantCategoryItemsData)
    const allCategoryItemsData = [...categoryItems, ...newCategoryItemsData]

    return allCategoryItemsData
}

function hasMoreCategoryItemsAvailable(categoryData) {
    return !!categoryData.next
}

async function getRelevantCategoryPageData(
    id,
    offset,
    categoryItems,
    setCategoryItems,
    setCategoryInfo,
    setShowLoadBtn
) {
    const categoryData = await getCategoryData(id, offset)
    const categoryInfo = await getCategoryInfo(id)

    if (!categoryData.list || !categoryInfo) {
        throw new Error('Unable to retrieve data for category with ID:', id)
    }

    setCategoryItems(getCategoryItemsInfo(categoryData, categoryItems))
    setCategoryInfo(categoryInfo)
    setShowLoadBtn(hasMoreCategoryItemsAvailable(categoryData))
}

function getRelevantData(props, categoryItems, categoryInfo) {
    const items = categoryItems && categoryItems.length > 0 ? categoryItems : props.items
    // merge, fetched category data is preferred
    return {
        ...props,
        ...categoryInfo,
        items,
    }
}

function getSlug(location = {}) {
    let path = ''
    path = location.pathname

    if (!path && typeof window !== 'undefined') {
        path = window.location.pathname
    }

    // remove '/watch/'
    path = path.substring(7)

    return path
}

const CategoryDetail = props => {
    const [categoryItems, setCategoryItems] = useState([])
    const [categoryInfo, setCategoryInfo] = useState({})
    const [showLoadBtn, setShowLoadBtn] = useState(true)
    const [offset, setOffset] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    const id = getIdentifierFromPath(props)

    useEffect(() => {
        setGaScreenName('Category Detail')
    }, [])

    useEffect(() => {
        getRelevantCategoryPageData(id, 0, [], setCategoryItems, setCategoryInfo, setShowLoadBtn)
            .then(_ => {
                setIsLoading(false)
            })
            .catch(err => {
                navigate('/404')
            })
    }, [id, props.title, props.items, props.dynamicContentCategoryTitles])

    const { id: edifloId, image, title, items = [] } = getRelevantData(props, categoryItems, categoryInfo)
    const firstItem = items[0] || {}

    const playerState = {
        ...firstItem,
        autoplayList: items.slice(1),
    }

    if (isLoading) {
        return null
    }

    return (
        <CategoryContainer>
            <HeroWrapper>
                <InfoWrapper>
                    <Info>
                        {title ? (
                            <>
                                <sub>CATEGORY</sub>
                                <h2>{title}</h2>
                            </>
                        ) : null}
                        <PlayBtn
                            to={`/player/${getSlug(props.location)}`}
                            state={playerState}
                            text="Play All"
                            onClick={() => sendGaPlayClickEvent(title, edifloId, 'Play All')}
                        />
                    </Info>
                </InfoWrapper>
                <ImageWrapper>
                    <Gradient></Gradient>
                    <CustomImage
                        imgSrc={getHeroImageSize(image, 100)}
                        isHero
                        imgStyle={{
                            width: '75vw',
                            margin: '0',
                        }}
                        alt={title}
                    />
                </ImageWrapper>
            </HeroWrapper>
            {addCategoryItemsGrid(
                id,
                items,
                setCategoryItems,
                setCategoryInfo,
                offset,
                setOffset,
                showLoadBtn,
                setShowLoadBtn
            )}
        </CategoryContainer>
    )
}

const mapStateToProps = state => {
    return {
        dynamicContentCategoryTitles: state.titles,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const ConnectedCategoryDetail = connect(mapStateToProps, mapDispatchToProps)(CategoryDetail)

const HeroWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    @media screen and (max-width: 672px) {
        position: relative;
    }
`

const InfoWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 25vw;
    width: 100%;

    @media screen and (max-width: 672px) {
        position: absolute;
        bottom: 0;
        max-width: unset;
    }
`

const ImageWrapper = styled.div`
    position: relative;
    height: calc(75vw * 0.5625);
    width: 75vw;
    margin-left: auto;

    @media screen and (max-width: 672px) {
        height: calc(100vw * 0.5625);
        width: 100vw;
        margin: 0;

        img {
            width: 100vw !important;
        }
    }
`

const Gradient = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 641px;
    background: linear-gradient(-90deg, rgba(20, 20, 20, 0) 0%, rgb(20, 20, 20) 100%);

    @media screen and (max-width: 672px) {
        background: linear-gradient(-180deg, rgba(20, 20, 20, 0) 0%, rgb(20, 20, 20) 100%);
        height: 64px;
        width: 100%;
        top: unset;
        bottom: 0;
    }
`

const CategoryContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    background-color: #141414;

    .grid {
        padding: 28px var(--discover-page-margin-desktop) 0 var(--discover-page-margin-desktop);

        @media screen and (max-width: 672px) {
            padding: 0 var(--discover-page-margin-mobile) 0 var(--discover-page-margin-mobile);
            margin-top: 90px;
        }
    }
`

const Info = styled.div`
    position: relative;
    margin-right: -400px;
    z-index: 3;
    word-wrap: break-word;
    margin-left: var(--discover-page-margin-desktop);

    a {
        text-decoration: none;
    }

    .play-btn {
        position: initial;
        margin-top: 15px;
    }

    h2 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: white;
        font-size: 36px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 44px;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        margin-top: 4px;
    }

    sub {
        opacity: 0.7;
        color: rgb(255, 255, 255);
        font-size: 12px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1.04px;
        line-height: 16px;
    }

    p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: white;
        opacity: 0.7;
        font-size: 16px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 20px;
        height: 60px;
        margin: 0;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (max-width: 672px) {
        margin-left: var(--discover-page-margin-mobile);
        margin-right: var(--discover-page-margin-mobile);

        .play-btn {
            position: absolute;
            bottom: -52px;
            margin: 0;
            width: 100%;
        }

        h2 {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 20px;
            margin-bottom: 0;
        }
        p {
            display: none;
        }
    }
`

export default ConnectedCategoryDetail
