import React from 'react'
import CategoryDetail from '../../components/category-detail'
import Layout from '../../components/layout'

const WatchCategory = ({ location }) => {
    return (
        <Layout>
            <CategoryDetail {...location.state} location={location} />
        </Layout>
    )
}

export default WatchCategory
